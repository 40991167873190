<div class="slider" style="position: relative;">
  <carousel>
    <slide>
      <div class="first">
        <div class="first-text">
          <div class="blocks">
            <div class="triangleBottomLeft"></div>
            <div class="block1">
              <span>{{'SLIDE.slide1-heading'|translate}}</span>
            </div>
            <div class="block2"></div>
          </div>
          <div class="blocks2">
            <div class="triangleBottomLeft"></div>
            <div class="block1">
              <span>{{'SLIDE.slide1-subheading'|translate}}</span>
            </div>
          </div>
          <div class="text">
            <p [innerHTML]="'SLIDE.slide1-txt'|translate"></p>
          </div>
          <button mat-button [routerLink]="'gallery'">{{'SLIDE.slide1-button'|translate}}</button>
        </div>
      </div>
    </slide>
    <slide>
      <div class="third">
        <div class="third-text">
          <div class="blocks">
            <div class="triangleBottomLeft"></div>
            <div class="block1">
              <span>{{'SLIDE.slide2-heading'|translate}}</span>
            </div>
            <div class="block2"></div>
          </div>
          <div class="blocks2">
            <div class="triangleBottomLeft"></div>
            <div class="block1">
              <span>{{'SLIDE.slide2-subheading'|translate}}</span>
            </div>
          </div>
          <div class="text">
            <p>
              {{'SLIDE.slide2-txt'|translate}}
            </p>
          </div>
          <button mat-button style="width: 300px"
                  [routerLink]="'technology'">{{'SLIDE.slide2-button'|translate}}</button>
        </div>
      </div>
    </slide>
    <slide>
      <div class="second">
        <div class="second-text">
          <div class="blocks">
            <div class="triangleBottomLeft"></div>
            <div class="block1">
              <span>{{'SLIDE.slide3-heading'|translate}}</span>
            </div>
            <div class="block2"></div>
          </div>
          <div class="blocks2">
            <div class="triangleBottomLeft"></div>
            <div class="block1">
              <span>{{'SLIDE.slide3-subheading'|translate}}</span>
            </div>
          </div>
          <div class="text">
            <p>
              {{'SLIDE.slide3-txt'|translate}}
            </p>
          </div>
          <button mat-button [routerLink]="'contact'">{{'SLIDE.slide3-button'|translate}}</button>
        </div>
      </div>
    </slide>
  </carousel>
</div>
<div class="ribbon">
  <div class="ribbon-text">
    <div class="text">
      <span style="font-weight: 500">{{'ribbon-heading' | translate}}</span>
      <span style="font-weight: 300">{{'ribbon-subheading' | translate}}</span>
    </div>
    <button mat-raised-button=""
            (click)="GOhomeButtonContact()"
            id="home-button-contact">{{'ribbon-button' | translate}}</button>
  </div>
</div>
<div class="wrapper">
  <div class="content">
    <div class="title">
      <h3>{{'HOME-SERVICES.service-heading'|translate}}</h3>
      <h1>{{'HOME-SERVICES.service-subheading'|translate}}<span>{{'HOME-SERVICES.service-subheading1'|translate}}</span>
      </h1>
      <p>
        {{'HOME-SERVICES.service-txt'|translate}}
      </p>
    </div>
    <div class="containers">
      <div class="container" [routerLink]="'tehnology'">
        <img src="../../../../assets/images/home-images/tehnologija.png">
        <button mat-button>{{'MENU.technology'|translate|uppercase}}</button>
      </div>
      <div class="container" [routerLink]="'aboutus'">
        <img src="../../../../assets/images/home-images/tehnology.png">
        <button mat-button>{{'MENU.aboutus'|translate|uppercase}}</button>
      </div>
      <div class="container" [routerLink]="'gallery'">
        <img src="../../../../assets/images/home-images/galerija.png">
        <button mat-button>{{'MENU.gallery'|translate|uppercase}}</button>
      </div>
    </div>
    <div class="map-content">
      <div class="map">
        <img src="../../../../assets/images/home-images/map.png">
      </div>
      <div class="text-container">
        <h3>{{'map-txt1'|translate}}</h3>
        <h1>{{'map-txt2'|translate}}</h1>
        <h1 style="color: #FFC200">{{'map-txt3'|translate}}</h1>
        <p>
          {{'map-txt4'|translate}}
        </p>
        <button mat-raised-button>{{'map-button'|translate}}</button>
      </div>
    </div>
  </div>

</div>
